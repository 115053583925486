$col-shade-green: #12805c;

.col-green {
    color: $col-shade-green !important;
}

.col-orange {
    color: #f5a31a !important;
}

.box-shadow {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2), 0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19);
}


#home {
    width:100vw;
    background-color:white;
    z-index: 1;
    position: relative;
    padding-top: 16vh;
    height: 100vh;
    display:flex;
    align-items: center;
    flex-direction: column;
    // font-family: Roboto;
    
    .home-wrapper{
        width:75rem;
        display: flex;
        flex-direction: column;
        // justify-content:center;
        align-items:center;
        overflow: hidden;
        padding-right:4.375rem;
        height:37.5rem;
        .ant-col-10{
            width:25rem;
            position: relative;
            top:3.125rem;
            h5.ant-typography{
                    font-style: normal;
                    font-weight: 300;
                    font-size: 1.563rem;
            }
        
            .wimage {
                // background-image: url("../static/assets/wheatCustomised.svg");
                background-repeat: repeat;
                width:24.5rem;
                height:1.75rem;
                color:#f5a31a;
                margin-top: 2rem;
            }
                
            .registerComponent {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                background-color:#F5A31A;;
                align-items: center;
                width:24.813rem;
                height: 4.563rem;
                padding:0.625rem;
                border-radius: 0.313rem;
                border: none;            
                    
                p {
                    font-size: 0.938rem;
                    margin-top: 0.625rem;
                    color:white;
                    font-weight: 700;
                }
                    
                Button {
                    width:7.5rem;
                    height: 2.75rem;
                    border-radius: 0.313rem;
                    background-color: #FFFFFF;
                    font-size: 1rem;
                    color:#f5a31a;
                    border: none;
                }
            }
                
            .googlePlay {
                position: relative;
                left:-0.625rem;
                width: 12.5rem;
                height: 2.769rem;
                margin-top: 3rem;
            
                Button,img {
                    width: 9.375rem;
                    height: 2.769rem;
                    margin-left: 0rem;
                    border: none;
                    }
            }
    
            .headFooter{
                position: relative;
                top:3.125rem;
                left:0rem;
                display:flex;
                flex-direction: column;
                align-self:start;
                width:31.25rem;
                height: fit-content;
    
                .firstL{
                    display: flex;
                    width:10.313rem;
                    height: 1.563rem;
                    justify-content: space-between;
                }
    
                .secondL{
                    width: 31.25rem;
                    height: 4.313rem;
                    background: #F6FAF8;
                    border-radius: 0.625rem;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    flex-direction: row;
                    text-decoration: underline;
    
                    p{
                       border-left: 0.063rem solid black;
                   }
               }
            }
        }
       
        .courselCircle{
            position: relative;
            left:9.25rem;
            z-index: 0;
        }
    }
    .pageDivider{
        position: relative;
        top:2.5rem;
    }
}
    
.courselUnit{
    width:30rem;
    height:30rem;
    border-radius: 50%;
    background-color:gray;
    margin:0.625rem;
}

.contentStyle{
    background-color:#f5a31a;
    width:30rem;
    height:30rem;
    border-radius: 50%;
    text-align: center;
}

#aim {
width: 100vw;
position: relative;
top:4rem;
height: 115rem;
z-index: 1;
scroll-margin: 12rem;
// font-family: Roboto;
display:flex;
align-items: center;
justify-content: center;
// background-color: #12805c;
    .aim-wrapper{
        display: flex;
        width: 62.5rem;
        align-items: center;
        justify-content:center;
        flex-direction: column;
        // background-color:orange;
    .at-present-text{
        font-style: normal;
        font-weight: 300;
        font-size: 1.5rem;
    }
    .aimFirstRow{
        position: relative;
        left: 2.5rem;
        display:flex;
        width: 62.5rem;
        justify-content: center; 
        align-self: center;
        // padding:0rem 12.5rem 0rem 12.5rem;
        margin-bottom: 4.375rem;
        margin-top: 9.375rem;
    
        .aim-Whatis{
            position: relative;
            // background-color: #12805c;
            .aim-header{
                font-style: normal;
                font-weight: 700;
                font-size: 2.25rem;
            }
            p{
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 300;
                font-size: 1.125rem;
            }
        }
        .videoContent{
            border-radius: 0.625rem;
        }
    }
    .why-vikasBandhu{
        margin-top: 2.5rem;
        font-style: normal;
        font-weight: 700;
        font-size: 2.25rem;
    }
        
    .seller-buyer-advantage{
        position: relative;
        width: 60rem;
        justify-content:flex-start;
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-top:3.125rem;
        margin-bottom:3.125rem;
        .headText{
            position: relative;
            left:10%;
            width:37.5rem;
            height:70px;
            margin-bottom: 3.125rem;
            .span-text{
                font-size: 1.875rem;
            }
            p{
                position: relative;
                margin:0px;
            }
        }
        
    
        .midSec-seller{
            margin-top: 7rem;
        }
    
        p{
            height:3.125rem;
            margin-bottom:1.25rem;
        }
         
        .seller-buyer-points{
            position: relative;
            left: 1.25rem;
            display: flex;
            justify-content: flex-start;
            justify-items: center;
            flex-direction: row;
            width:20rem;
            height: 3.75rem;
            margin: 0.313rem;
            padding: 0.625rem;
    
            div{
                width:2rem;
                height: 2rem;
                background-color:  #F7682B;
                border-radius: 50%;
                text-align: center;
                text-justify: center;
                font-size: large;
                color:#FFFFFF;
            }
    
            p{
                width:16rem;
                height: 3.188rem;
                border-radius:0.625rem;
                // background-color: #F6FAF8;
                margin-left: 0rem;
                line-height: normal;
                font-style: normal;
                font-size: 0.875rem;
            }
    
            .aim-seller-buyer-points-kn{
                width:18.75rem;
                height: 3.125rem;
                border-radius:0.625rem;
                // background-color:rgba(245, 163, 26, 0.05);
                margin-left: 0.313rem;
                line-height:1;
                font-style: normal;
                font-size: 0.875rem;
            }
        }
    }
    
    .manyMore{
    margin-top: -2.5rem;
    font-size: 1.125rem;
    }
    
    hr.solid{
        width: 14rem;
    }
    
    .seventhBox{
        margin-top: 2.5rem;
        width:36.438rem;
        height: 4.5rem;
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 150%;
        text-align: center;
    }
    
    .ninethBox{
            position: relative;
            font-style: normal;
            font-weight: 300;
            font-size: 1.5rem;
            top:3.25rem;
    }
    
        .tenthBox{
            position: relative;
            top:4.25rem;
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            width:62.5rem;
        // background-color: #f5a31a;
    
            .ant-col-8{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 8.125rem;
                height: 8.125rem;
                border-radius: 50%;
                background-color: rgba(245, 163, 26, 0.05);
                margin: 0.625rem;
                text-align: center;
        
            .firstheading{
                width:15rem;
                height: 1.313rem;
                font-style: normal;
                font-weight: bold;
                font-size: 1.125rem;
            }
    
                p{
                    width:15rem;
                    height: 1.313rem;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.063rem;
                }
            }
        }
        .pageDivider{
        position: relative;
        top:8rem;
        }
    }
}

#ecosystem {
    position: relative;
    top:15rem;
    height:62.5rem;
    width: 100%;
    z-index: 1;
    display:flex;
    align-items: center;
    justify-content: center;
    scroll-margin: -3.125rem;

.ecosystem-wrapper{
    width: 62.5rem;
    margin-top: 3.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    div.ant-col.ant-col-6{
        position: relative;
        top: -1.125rem;

        div{
            display: flex;
            flex-direction: row;
            width: fit-content;
            height: fit-content;

            img{
                height: 2.625rem;
            }
            p{
                position: relative;
                top: 6.25rem;
                width: 11.125rem;
                left: 2rem;
                 }
                 img{
                    top: 6.25rem;
                    left:1rem;
                }
        }
    }
   
    
}
.firstHead{
   text-align: center;
    margin-top: 10.625rem;
    width: 100vw;
    height: 2.625rem;
    font-style: normal;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
}

img{
    position: relative;
    top:6.25rem;
}
.keyFeatures{
    position: relative;
    top:11.25rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
}
.lastContent-ecosystem{
    position: relative;
    top:11.25rem;
    width:75rem;
    height: 9.375rem;
    // background-color: #f5a31a;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    .lastContentBadges{
        position: relative;
        top:3.125rem;
        width:6.25rem;
        height: 6.25rem;
        border-radius: 50%;
        background-color:  rgba(245, 163, 26, 0.05);
        display: flex;
        flex-direction: column;
        margin: 0rem 3.125rem 0rem 3.125rem;
        align-items: center;
        text-align: center;
       div{
           position: relative;
           top:-7.188rem;
            width:2.5rem;
            height: 2.5rem;
            margin:0.125rem;
            z-index: 1;
        }
        .insideText{
            width:12.663rem;
            height: 1.313rem;
            font-style: normal;
            font-weight: bold;
            font-size: 1.125rem;
            margin:0rem;
        }
        .outerText{
            width:12.663rem;
            height: 2.5rem;
            font-style: normal;
            font-weight: normal;
            font-size: 0.875rem;
        }
    }
}
.pageDivider{
    position: relative;
    top:18.75rem;
}
        .ecosystem-title-kn {
            position: relative;
            width:fit-content;
        }
}

#commodities {
    position: relative;
    top:35rem;
    width:100vw;
    height:37.5rem;
    display: flex;
    // background-color: orange;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    bottom: 10rem;
    scroll-margin: 3rem;
    
    .commodities-wrapper{
        width: 62.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:space-evenly;
        height: 50rem;
        .commoditiesTitle{
            margin-top: 3.75rem;
            font-style: normal;
            font-weight: bold;
            font-size: 2.25rem;
            line-height: 2.625rem;
            text-align: center;
        }
        .commoditiesParagraph{
            text-align: center;
            width:50rem;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
            font-size: 1.25rem;
        }
    .CropImages_sec{
        width:62.5rem;
        height:9.375rem;
        display: flex;
        flex-direction: row;
        justify-content:space-evenly;
        // background-color:green;
        div{
            text-align:center;
            p{
                margin-top:1.25rem;
                font-style: normal;
                font-weight: bold;
                font-size: 1.125rem;
            }
            img{
                width:8rem;
                height:8rem;
            }
        }
    }
    .pageDivider{
        position: relative;
        top:4rem;
    }
        // Kannada Styling
        .commodities-title-kn {
           font-size: 2rem;
        }
    
        .commodities-title {
           font-size: 2.5rem;
        }
    
        // Kannada Styling
        .commodities-paragraph-kn {
            width: 100%;
           font-size: 1rem;
            line-height: 2rem;
        }
    
        .commodities-paragraph {
            width: 100%;
           font-size: 1rem;
            line-height: 2rem;
        }
    
        .commodities-list {
            float: left;
            width: 10rem;
            margin-top: 1.5rem;
        }
    
        .commodities-name {
           font-size: large;
        }
    
        .commodities-image {
            width: 9rem;
            height: 10rem;
        }
    
        .wheat-image {
            margin-top: 1rem;
            width: 90%;
        }
    }
}

#users {
    scroll-margin: 6rem;
    position: relative;
    bottom: 10rem;

    // Kannada Styling
    .user-title-kn {
        text-align: center;
    font-size: 2rem;
    }

    .user-title {
        text-align: center;
    font-size: 2.5rem;
    }

    .user-column {
        margin-left: 3rem;

        // Kannada Styling
        .user-card-kn {
            background-color: #e0efea;
            width: fit-content;
        font-size: 1rem;
            height: 26rem;
            text-align: center;
        }

        .user-card {
            background-color: #e0efea;
            width: fit-content;
        font-size: 1rem;
            height: 26rem;
            text-align: center;
        }
    }

    // Kannada Styling
    .card-subtitle-kn {
    font-size: 1rem;
    }

    // Kannada Styling
    .card-text-kn {
    font-size: 0.9rem;
    }

    .card-text {
    font-size: 1rem;
    }

    .user-image {
        width: 7.813rem;
        height: 7.813rem;
    }

    .transport-image {
        margin: 2rem;
        width: 90%;
    }
}

.about-us-video-modal {
    .ant-modal-body {
        height: 90vh;
    }
}

#aboutUs {
    margin: 2.5rem;
    width: 95%;
    scroll-margin: 6rem;
    padding: 1rem 2rem;
    position: relative;
    bottom: 10rem;
    background-color: #fbfbfb;

    // Kannada Styling
    .about-us-title-kn {
        padding-top: 1rem;
    font-size: 2rem;
    }

    .about-us-title {
        padding-top: 1rem;
    font-size: 2.5rem;
    }

    .outline-image {
        position: relative;
        float: right;
        bottom: 5rem;
        height: 10rem;
    }

    // Kannada Styling
    .outline-paragraph-kn {
    font-size: 1rem;
        width: 90%;
    }

    .outline-paragraph {
    font-size: 1.2rem;
        width: 90%;
    }

    .vision-card {
        width: fit-content;
        height: 17rem;
        background-color: #e0efea;
        text-align: center;

        // Kannada Styling
        .card-paragraph-kn {
            width: 15rem;
        font-size: 1rem;
            margin: 0 auto;
        }

        .card-paragraph {
            width: 18rem;
        font-size: large;
            margin: 0 auto;
        }
    }

    .vision-div {
        padding-top: 1rem;

        // Kannada Styling
        .vision-paragraph-kn {
            width: fit-content;
        font-size: 1rem;
        }

        .vision-paragraph {
            width: fit-content;
        font-size: large;
        }
    }
}

#team {
    position: relative;
    z-index: 0;
    top:39rem;
    width:100vw;
    height:fit-content;
    // background-color:orange;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    scroll-margin:7rem;
   
    .teamWrapper{
        width:75rem;
        height:fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background-color:green;
        .teamTitle{
            margin-top: 1.25rem;
            font-style: normal;
            font-weight: 700;
            font-size: 2.25rem;
            text-align: center;
        }
       
        .Founders_sec{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;
            width: 62.5rem;
            height: 50rem;
            background: white;
            border: 0.063rem solid rgba(18, 128, 92, 0.19);
            box-sizing: border-box;
            border-radius: 0.625rem;
            .FoundersTitle{
                width: 8rem;
                position: relative;
                font-style: normal;
                font-weight: 700;
                font-size: 1.5rem;
                left: 3.813rem;
                top: -3rem;
                align-self: start;
                background-color: white;
                text-align: center;
            }
            .FoundersRow{
                width: 59.375rem;
                height: 12.5rem;
                background-color: rgba(245, 163, 26, 0.05);
                padding: 1.25rem;
                border-radius: 0.625rem;
                border: 0.063rem solid rgba(245, 163, 26, 0.25);
                img{
                    width:9.375rem;
                    height:9.375rem;
                }
                .FounderPara{
                    width: 31.25rem;
                    height: 9.375rem;
                    margin-top: 0.625rem;
                    // background-color: #12805c;
                    box-sizing: border-box;
                    line-height: 100%;
                    p{
                        margin: 0rem;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 0.875rem;
                        color: #12805C;
                    }
                    .para{
                        margin-top: 0.625rem;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: normal;
                        color: #000000;
                    }
                }
            }
        }
        .Management_sec{
            margin-top: 5rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;
            width: 62.5rem;
            height: 18.75rem;
            background: white;
            border: 0.063rem solid rgba(245, 163, 26, 0.3);
            box-sizing: border-box;
            border-radius: 0.625rem;
            .FoundersTitle{
                width:15rem;
                position: relative;
                font-style: normal;
                font-weight: 700;
                font-size: 1.5rem;
                left: 3.813rem;
                top: -1.5rem;
                align-self: start;
                background-color: white;
                text-align: center;
            }
            .ManagementRow{
                width: 59.375rem;
                height: 12.5rem;
                margin-bottom: 3.125rem;
                background-color:rgba(18, 128, 92, 0.05);
                padding: 1.25rem;
                border-radius: 0.625rem;
                border:  0.063rem solid rgba(18, 128, 92, 0.25);
                img{
                    width:9.375rem;
                    height:9.375rem;
                }
                .FounderPara{
                    width: 31.25rem;
                    height: 9.375rem;
                    margin-top: 0.625rem;
                    // background-color: #12805c;
                    box-sizing: border-box;
                    line-height: 100%;
                    p{
                        margin: 0rem;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 0.875rem;
                        color: #12805C;
                    }
                    .para{
                        margin-top: 0.625rem;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: normal;
                        color: #000000;
                    }
                }
            }
        }
    }
    .pageDivider{
        position: relative;
        top: 4.125rem;
    }
}

#legal {
    width: 100vw;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 55rem;
    bottom: 10rem;
    scroll-margin: 17rem;
    // background-color: $col-shade-green;
    color: black;
    z-index: 1;
    .legalWrapper{
        width: 50rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        // background-color:orange;
        
        .legal-title {
            position: relative;
            top: -9.375rem;
            color: black;
            font-style: normal;
            font-weight: 700;
            font-size: 2.25rem;
    }
    .legal-tab {
        position: relative;
        bottom: 7rem;

        .ant-tabs-tab,
        .ant-tabs-tab-active {
            width: 25rem;
            display: inline-flex;
            padding: 0.3rem 1rem;
            font-size: 1rem;
            border-radius: 0.625rem 0.625rem 0rem 0rem;
            color: white;
        }

        .ant-tabs-tab-btn{
            width: 100%;
            // background-color:orange;
            color: black;
            text-align: center;
        }

        .ant-tabs-nav {
            position: relative;
            top: 1rem;
        }

        .ant-tabs-tab {
            background-color: rgba(196, 196, 196, 0.15);
            border-bottom: 0.188rem solid orange;
        }


        .ant-tabs-tab-btn-active {
            color: orange !important;
        }

        .ant-tabs-tab-active {
            background-color: $col-shade-green !important;
        }

        .ant-tabs-content-holder {
            width: 100%;
            height: fit-content;
            position: relative;
            border-radius: 0.3rem;
            color: #000000;
            // background-color: #339273;

            ul {
                li {
                    padding: 2em;
                    padding-bottom: 0;
                    color: black;
                    list-style-type: disc;
                }
            }

            .read-more-button {
                background: #F5A31A;
                color: white;
                border-radius: 0.313rem;
                margin-bottom: 2rem;
            }
        }
    }
}
    .pageDivider{
        position: relative;
        top:-6.25rem;
    }
}

#contactUs {
    position: relative;
    top: 49rem;
    height: 40rem;
    // margin-top: 6.25em;
    width:100vw;
    // background-color:orange;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-evenly;
    scroll-margin: 6rem;
    z-index: 1;
    .contact-us-title{
        position: relative;
        top:1.563rem;
        font-style: normal;
        font-weight: 700;
        font-size: 2.25rem;
    }
    .contactWrapper{
        position: relative;
        top:6.25rem;
        // background-color: #12805c;
        width:62.5rem;
   
    .contact-us-info {
        font-size: 1.2rem;
        left:3.125rem;
        .mapouter{
            position:relative;
            top:-3.125rem;
            width:100%;
            height:11.125rem;
            border-radius: 0.625rem;
            border: 0.063rem solid #F5A31A;
            overflow-y: hidden;
            .gmap_canvas{
                overflow:hidden;
                background:none!important;
                width:100%;
                height:11.188rem;
                .gmap_iframe{
                    height:11.188rem !important;
                }
            }
        }
    
            .anticon {
                font-size: x-large;
                color: #f5a31a;
                padding: 1rem 1rem;
                background: rgba(245, 163, 26, 0.05);
                border-radius: 50%;
            }
    
            .contact-address {
                position: relative;
                bottom: 2.75rem;
                left: 3.35rem;
            }
    }
    
        .write-feedback {
            position:relative;
            top:3.125rem;
            background: rgba(245, 163, 26, 0.05);
            padding: 1rem;
            height:31.25rem;
            margin-bottom: 2rem;
            margin-top: -5.625rem;
            border-radius: 0.313rem;
    
            .feedback-form {
                text-align:center;
                padding:0rem 1.875rem 0rem 1.875rem;
                width:25rem;
                .ant-form-item{
                    margin-bottom:0rem;
                }
                .ant-btn{
                    color:white;
                    background-color: #F5A31A;
                    height:2.5rem;
                    margin-top: 0.625rem !important;
                }
            }
        }
    }
    .pageDivider{
        position: relative;
        top:10.25rem;
    }
}

#updates{
    position: relative;
    top:60rem;
    width: 100vw;
    padding:1.25rem;
    z-index: 1;
    // background-color: #12805c;
    display: flex;
    align-items: center;
    justify-content:center;
    scroll-margin: 7rem;
    
    .updateWrapper{
    width:62.5rem;
    margin-top:0.625rem;
    margin-bottom: 0.625rem;
    box-shadow: 0.25rem 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.16);
    .updates-title{
        color:black;
        text-align:center;
        font-style: normal;
        font-weight: 700;
        font-size: 2.25rem;
    }
        .card{
            width:56.25rem;
            height: 25rem;
            padding: 1.25rem;
            // background-color: #12805c;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            img{
                width:25rem;
                height: 18.75rem;
            }
            p{
                position: relative;
                left: 1.25rem;
                text-align: left;
                font-size: 1rem;
            }
        }
    }
}

// Styling for web page
@media screen and (min-width: 767px) {
    .mobile-home-content {
        display: none;
    }
}

//Styling for mobile or tab
@media screen and (max-width: 767px) {
    .mobile-display-none {
        display: none !important;
    }
    html, body{
        width:100vw !important;
        overflow-x:hidden !important;
        margin:0rem;
        padding:0rem;
    }

    .fixed-card-join {
        position: fixed;
        top: 82.2vh;
        left: 0vw;
        z-index: 1;

        .join-us {
            width: 100vw;
            height: 12.5vh;
            padding: 0;
            margin: 0rem;
            border-radius: 0.4rem;
            text-align: left;

            .fixed-title {
            font-size: 1rem;
                position: relative;
                left: 5rem;
            }

            .join-image {
                width: 12.5vw;
                height: 12.5vw;
                position: relative;
                bottom: 3rem;
            }

            .join-us-reg {
                width: 25vw;
                height: 5vh;
            font-size: 1rem;
                position: relative;
                bottom: 7rem;
                left: 16rem;
            }
        }
    }

#home{
    width:100%;
    height:50rem;
    padding:2.625rem;
    align-items: center !important;

    .ant-row{
        position: relative;
        left:1.875rem;
        .ant-col-8{
            .wimage,.registerComponent{
                width:80%;
            }
            h5{
                width:80%;
            }
            .headFooter{
                width:18.75rem;
                height:18.75rem;
                .secondL,.ant-breadcrumb{
                    width:18.75rem;
                    height:12.5rem;
                    .ant-breadcrumb{
                        display: flex;
                        flex-direction:column;
                        align-items:center;
                        justify-content: space-evenly;
                    }
                }
            }
        }
    }
}

#aim{
width:100vw;
// left:3.125rem;
padding:5rem;
top:-5rem;
// background-color: orange;
height:fit-content;

    .aim-wrapper{
        display: flex;
        align-items: center;
        justify-content:center;
        flex-direction: column;
        .aimFirstRow{
            .aim-Whatis{
                width:18.75rem;
                h2{
                    width:100vw;
                    position: relative;
                    left:-2.5rem;
                }
                p{
                    width:75vw;
                }
            }
            .videoContent{
                position: relative;
                width:18.75rem;
                height:12.5rem;
                top:1.25rem;
                left:-1.875rem;
            }
        }

        h2,span.ant-typography{
            width:100vw;
            position: relative;
            padding-left:10vw;
        }
        
        .seller-buyer-advantage{
            position: relative;
            left:24rem;
            padding:2rem;
            .headText{
                position: relative;
                width:18.75rem;
                left:-0.625rem;
                margin-bottom: 3.125rem;
                text-align: center;
            }
        }

        .seventhBox{
            width:18.75rem;
            height:fit-content;
        }

        .advantageBadges{
            // background-color: #F5761A;
            position: relative;
            left:-1.25rem;
            .ant-col-6 img{
                    width:9.25em;
                    height:9.25em;
            }}

            .advantageBadges-1{
                .ant-col-6 img{
                    width:9.25em;
                    height:9.25em;
                }
            }

            div.ant-row.tenthBox{
                width:18.75rem;
                position: relative;
                top:1.875rem;
            }

            .ninethBox{
                position: relative;
                top:1.25rem;
            }
            .pageDivider{
                position: relative;
                top:3.25rem;
            }
    }
}

#ecosystem {
    width:100vw;
    height:fit-content;
    top:-6.25rem;
    //background-color:orange;
      .ecosystem-wrapper{
          top:0rem;
          align-items:center !important;
          .iphone-screen-mobile{
              width:80vw;
            //   background-color:green;
              height:80vh;
            div.ant-col.ant-col-24{ 
                        div{
                            position: relative;
                            left:-0.313rem;
                            top:-0.625rem;
                        }  
                div p{
                  position: relative;
                  top: 3.75rem;
                  left:5.625rem;
                  width:12.5rem;
                  height:6.25rem;
                //   background-color:rgb(191, 199, 191);
                }
            }
                 img{
                    top: 6.25rem;
                    left:4rem;
                }
          }
          .firstHead{
              font-size:1.875rem;
              text-align:center;
          }
          .keyFeatures{
              position: relative;
              top:13.75rem;
              text-align:center;
          }
          img{
            position: relative;
            top:12.5rem;
          }
          .lastContent-ecosystem{
              flex-direction: column;
              justify-content:space-evenly;
              height: fit-content;
              .lastContentBadges{
                  margin:1.563rem 0rem 1.563rem 0rem;
                  div img{
                      top:6.25rem;
                  }
              }
            //   background-color:green;
          }
          .pageDivider{
            position: relative;
            top:15rem;
        }
        
    }
}

    #commodities {
        top:7.5rem;
        // background-color:green;
        .commoditiesParagraph{
            width:100vw;
            padding:0.625rem;
        }
        .CropImages_sec{
            position: relative;
            top:18.75rem;
            flex-direction: column;
        }
        .ant-divider-plain.ant-divider-with-text{
            position: relative;
            top:37.5rem;
        }
    }

    #users {
        width: 100vw;
        bottom: 0;
        background-color: #ffffff;

        .user-title {
        font-size: 1.25rem;
            margin-left: 1rem;
            padding-top: 0;
        }

        .user-column {
            width: 90vw;
            margin-left: 5vw;

            .user-card {
                width: 90vw;
            font-size: 1rem;
                height: fit-content;
                border-radius: 0.3rem;
                padding: 0;

                .user-card-title {
                font-size: 1.25rem;
                }

                .subtitle {
                font-size: 1rem;
                    padding-left: 7rem;
                    text-align: left;
                }

                .user-image {
                    border: 0.063rem solid white;
                    background-color: #ffffff;
                    border-radius: 0.3rem;
                    width: 25vw;
                    height: 25vw;
                    position: absolute;
                    bottom: 1.5rem;
                    right: 3.5rem;
                }

                .user-paragraph {
                    text-align: left;
                font-size: 1rem;
                    margin: 0;
                }
            }
        }

        .buyer-column {
            position: relative;
            top: 21rem;
            right: 11.25rem;
        }

        .transport-image {
            margin-left: 2.5vw;
            position: relative;
            top: 18rem;
            width: 95vw;
            margin-bottom: 15rem;
            margin-right: 0;
        }
    }

    #aboutUs {
        width: 100vw;
        background-color: #ffffff;
        height: 100vh;
        margin: 0;
        bottom: -6vh;
        padding: 0rem !important;

        .about-us-title {
            font-size: 1.25rem;
            padding: 2rem;
        }

        .outline-image{
            position: relative;
            bottom: 4rem;
            height: 25vw;
            margin-right: 2vw;
            width: 25vw;
        }

        .outline-paragraph{
            padding-left: 2rem;
            font-size: 1rem;
            width: 90vw;
        }

        .vision-card{
            width: 90vw;
            height: 22vh;
            margin-left: 5vw;
            text-align: left;

            .about-us-card-image{
                margin-top: 2vh;
                width: 15vw;
                height: 15vw;
            }

            .card-title{
                text-align: center;
                width: max-content;
                float: right;
                position: relative;
                right: 9.5rem;
            font-size: 1.25rem;
            }

            .card-paragraph{
                width: 65vw;
                float: right;
                position: relative;
                left: 1rem;
                bottom: 3.5rem;
            font-size: 1rem;
            }
        }

        .phylosophy-card{
            position: relative;
            top: 12rem;
            right: 12rem;
            height: 20vh;
            margin-bottom: 12rem;

            .card-title {
                right: 6.75rem;
            }
        }

        .vision-div {
            padding-left: 2rem;
            width: 95vw;
        }

        .vision-div-title,
        .vision-paragraph {
        font-size: 1rem !important;
        }
    }

    #team{
       top:43.75rem;

        .teamWrapper{
           width:100vw;
           //background-color:green;
           display: flex;
           align-items: center;
           justify-content: center;
           .Founders_sec{
               width:18.75rem;
               height:90.625rem;
               display:flex;
               justify-content: center;
               text-align: center;
               .FoundersTitle{
                   top:-1.25rem;
                   left:33%;
               }
                .FoundersRow{
                   width:15.625rem;
                   height:29.375rem;
                   margin-bottom:0.625rem;
                   //background-color:green;
                   .ant-col-6 img{
                       position: relative;
                       left:50%;
                   }
                   .FounderPara{
                       width:18.75rem;
                       position: relative;
                    //    background-color: green;
                       top:-3.125rem;
                   }
                }
           }
            .Management_sec{
            width:18.75rem;
            height:70.313rem;
            display:flex;
            justify-content: center;
            text-align: center;
            .FoundersTitle{
                position: relative;
                top:-1.25rem;
                left:3.125rem;
            }
                .ManagementRow{
                width:15.625rem;
                height:21.875rem;
                margin-bottom:0.625rem;
                .ant-col-6 img{
                    position: relative;
                    top:-0.625rem;
                }
                .ant-col-18{
                    position: relative;
                    top:-1.875rem;
                }
                }
            }
        }
    }

    #legal{
        top:37.5rem;
        width:100vw;
        padding:1.25rem;
        // background-color:orange !important;
        height:106.25rem;
        .legalWrapper{
            width:100vw !important;
            padding: 1.25rem !important;
            // background-color:green ;
            .legal-title{
                position: relative;
                top:-6.25rem;
            }
            .legal-tab{
                width:100vw !important;
                .ant-tabs-tab{
                    width:50vw;
                }
            }
        }
        .pageDivider{
            position: relative;
            top:-9.375rem;
        }
    }

    #contactUs{
    //   background-color: #F5761A;
      top:18.875rem;
      width:100vw;
      .contact-us-title{
          position: relative;
          top:6.25rem;
      }
      .contactWrapper{
          width:100vw;
          display: flex;
          justify-content: center;
          .ant-row{
              display: flex;
              justify-content:start;
              padding:0;
              width:100vw;
            //   background-color:green;
              .ant-col-12{
                  position: relative;
                  left:-0.625rem;
                  padding-left:1.25rem;
                  margin-bottom: 0.625rem;
                  font-size: 0.625rem;
                  .contact-details{
                      height:8.125rem;
                      width:80vw;
                      text-align: center;
                    //   background-color:#f5a31a;
                  }
                  .mapouter{
                      position: relative;
                      top:-7.5rem;
                  }
              }
              .write-feedback{  
                  top:-0.625rem;
                  padding:0.625rem;
                  display: flex;
                  justify-content:center;
                  .feedback-form{
                      height:37.5rem;
                    //   background-color:#f5a31a;
                     #miform{
                        .ant-form-item{
                            width:18.75rem;
                        }
                     }
                  }
              }
          }
      }
      .pageDivider{
        position: relative;
        top:6.25rem;
    }
    }

    #updates{
        top:37.5rem;
        // background-color:green;
        .updateWrapper{
            width:100vw;
            .site-collapse-custom-collapse{
                .site-collapse-custom-panel{
                    .card{
                        width:100vw;
                        display:flex;
                        flex-direction:column;
                        justify-content:center;
                        height: 31.25rem;
                        img{
                            position: relative;
                            top:0.313rem;
                            width:12.5rem;
                            height:12.5rem;
                        }
                        p{
                            position: relative;
                            top:0.313rem;
                            width:18.75rem;
                            height:18.75rem;
                        }
                    }
                }
            }
        }
    }
}
