@mixin formStyles {
  background-color: #fff;
  margin-top: 30px;
  padding: 10px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  label {
    color: rgb(94, 88, 88);
    font-weight: bold;
  }
  .ant-input {
    padding: 10px;
    border: 2px solid #b3abab;
    border-radius: 4px;
    width: 200px;
    height: 30px;
    background-color: #faf5f5;
    transition: border-color 0.3s, box-shadow 0.3s;
    &:focus {
      border-color: #40a9ff;
      box-shadow: 0 0 8px rgba(64, 169, 255, 0.2);
    }
  }
 .ant-picker {
    padding: 10px;
    border: 2px solid #b3abab;
    border-radius: 4px;
    width: 150px;
    height: 30px;
    background-color: #faf5f5;
    transition: border-color 0.3s, box-shadow 0.3s;
    &:focus {
      border-color: #40a9ff;
      box-shadow: 0 0 8px rgba(64, 169, 255, 0.2);
    }
  }
  .ant-btn {
    padding: 10px 10px;
    height: 40px;
    width: 120px;
    margin: 10px 10px;
    border: 2px solid #40a9ff;
    border-radius: 4px;
    background-color: #40a9ff;
    color: #fff;
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
    &:hover {
      background-color: #69c0ff;
      border-color: #69c0ff;
      box-shadow: 0 0 8px rgba(64, 169, 255, 0.2);
    }
    &:focus {
      background-color: #69c0ff;
      border-color: #69c0ff;
      box-shadow: 0 0 8px rgba(64, 169, 255, 0.2);
    }
    &.ant-btn-primary {
      background-color: #1890ff;
      border-color: #1890ff;
    
      &:hover {
        background-color: #40a9ff;
        border-color: #40a9ff;
      }
      &:focus {
        background-color: #40a9ff;
        border-color: #40a9ff;
      }
    }
  }
}
@mixin tableStyles {
  background-color: rgb(221, 241, 241);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  thead {
    background-color: #f0f0f0;
    th {
      padding: 10px;
      text-align: left;
      font-weight: bold;
      color: #555;
      border-bottom: 2px solid #ddd;
    }
  }
  tbody {
    tr {
      &:nth-child(even) {
        background-color: #f5f5f5;
      }
      &:hover {
        background-color: #e6f7ff;
      }
      td {
        padding: 10px;
        border-bottom: 1px solid #ddd;
        color: #555;
      }
    }
  }
}
@mixin totalsStyles {
  display: flex;
  align-items: center;
  padding: 1rem;

  background-color: #f4e4e4;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  strong {
    font-size: 0.8rem;
    color: #2c2f32;
    margin: 10px 120px;
    padding: 0;
  }
}
@mixin inputAffixWrapperStyles {
  width: 200px;
  height: 40px;
  padding: 10px;
  outline: 0;
  border: 0;
  margin-bottom: 10px;
  border: 2px solid rgb(211, 164, 164);
}
@mixin inventoryStyles{
    display:flex;
    justify-content:center;
    gap:20px;
    padding:10px;
    background-color: #e1e4ee;
    border: 1px solid #d1d9e6;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    Button {
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      width:150px;
      height: 30px;
      font-size: 14px;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0056b3; 
      }
  
      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
      }
    }
  }
  @mixin inventorytotalStyles{
    display:flex;
    justify-content: flex-end;
    padding: 1rem;
    background-color: #f4e4e4;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  strong {
    font-size: 0.8rem;
    color: #2c2f32;
    margin: 10px 10px;
    padding: 0;
  }

  }
  @mixin modalStyles{
    width:fit-content;
    background-color: #0056b3;
  }
.salesAndPurchase {
  position: absolute;
  top: 120px;
  width: 100%;
  padding: 20px;
  .billOfSupply,
  .supplierOrder,
  .purchaseEntry,
  .purchaseReturn,
  .grnEntry,
  .salesReturn,
  .InventoryReport {
    .ant-form-horizontal {
      @include formStyles;
    }
    .ant-form-vertical {
      @include formStyles;
    }
    .ant-table {
      @include tableStyles;
    }
    .totals {
      @include totalsStyles;
    }
    .inventorytotal{
      @include inventorytotalStyles;
    }
    .purchaseentry-total {
      @include totalsStyles;
    }
    .ant-input-affix-wrapper {
      @include inputAffixWrapperStyles;
    }
    .inventorybuttons{
      @include inventoryStyles;
    }
  }
}
