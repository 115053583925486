.otp-modal {
    .custom-otp-modal {
        .ant-modal-content {
            width: 24em;
    
            .ant-modal-header {
                padding-bottom: 0;
                border: none;
            }
    
            .ant-modal-body {
                padding-top: 0;
    
                .custom-color-change {
                    color: #cacaca !important;
                    
                    .ant-statistic-content-value {
                        color: #cacaca !important;
                        font-size: initial;
                        padding-bottom: 0.5em;
                    }
                }

                .add-margin-bottom {
                    margin-bottom: 0.5em;
                }

                .confirm-otp-modal-warning {
                    background-color: white;
                    border: none;
                    line-height: 1em;
                }

                .confirm-otp-divider {
                    margin: 0;
                }
            }
        }
    }
}

.register_model{
    padding-top: 3rem;
}

.ant-btn.ant-btn-default.ant-btn-lg.width-full.color-green-shade.custom-default-button,
.ant-btn.ant-btn-default.ant-btn-lg.width-full.null.custom-default-button
{
    width: 100%;
}

.ant-btn.ant-btn-default.ant-btn-lg.width-full.color-green-shade.custom-default-button:hover,
.ant-btn.ant-btn-default.ant-btn-lg.width-full.color-green-shade.custom-default-button:focus,
.ant-btn.ant-btn-default.ant-btn-lg.width-full.null.custom-default-button:hover,
.ant-btn.ant-btn-default.ant-btn-lg.width-full.null.custom-default-button:focus,
.focus-button-item {
    color: white !important;
    border-color: #12805c !important;
    background-color: #12805c;
}

.ant-modal-body
{
    height: auto;
}

