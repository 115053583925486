.updateCardBody {
  border-radius: 1rem;
  height: 34rem;
  .deleteBtn {
    background-color: red;
    border-color: black;
    border-radius: 1rem;
    color: white;
  }
}
  .card-paragraph.expanded {
    overflow: auto;
    max-height: 40rem; /* Set a maximum height for the expanded state */
  }