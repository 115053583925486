.landing-page-header-bar {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 0.5rem 10rem 2rem 10rem;
    height:6rem;
    width: 100vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    position: fixed;
    z-index: 3;
    margin-bottom:100px;
    .landing-page-header-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between; 
        align-items: center;   
        width:1000px;
        // background-color:green;
        .logo {
            padding-top: 20px;
            width: 6.5rem;
            height: 6.5rem;
            float: left;
        }
    
        .header-breadcrumb {
            width: fit-content;
            font-size: 1rem;
            padding-top: 1.5rem;
        }
    
        .custom-login-button{
            padding-top: 1.5rem;
            color:green;
            width:5rem;
            height:3.125rem;
        }
        .custom-register-button {
            position: relative;
            top:10px;
            width: 6.375rem;
            height: 2.5rem;
            border-radius: 1.25rem;
        }
    
        .action-buttons {
            float: right;
        }
    }

  
}

.register-popup-container {
    .custom-register-modal {
        float: right;

        .ant-modal-content {
            background-color: #f2f2f2;
        }
    }

    .register-basic-form {
        .ant-form-item {
            margin-bottom: 0.5rem;
        }
    }
}

.login-register-buttons{
    padding-top: 25px;
}

.profile-avatar {
    position: relative;
    top: -0.4em;
    border: 0.01em solid black;
}



//Hide the Mobile Drawer Button
@media screen and (min-width: 767px) {
    .mobile-visible {
        display: none;
    }
}

// Responsive Styling for mobile and tab portrait
@media screen and (max-width: 767px) {
    .landing-page-header-bar {
        width: 100vw;
        height: 12vh;
    }

    .logo {
        width: 5rem !important;
        height: 3rem !important;
    }

    .header-breadcrumb {
        display: none;
    }

    .mobile-header-breadcrumb {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        line-height: 2rem;
        padding-top: 1rem;
    }

    .mobile-header-breadcrumb:focus {
        background-color: #12805c21;
    }

    .mobile-visible {
        position: relative;
        top: 1.5rem;
        left: 7.5rem;
    }

    .drawer-register-button {
        width: 90%;
        height: 3rem;
        font-size: 1.25rem;
        border-radius: 0.3rem;
    }

    .contact-details {
        color: #319171;
        text-align: right;
    }

    .custom-login-button,
    .custom-register-button {
        display: none;
    }
}
