@media screen and (min-width: 767px) {
  .mobile-visible {
    display: none;
  }
}

// Styling for mobile
@media screen and (max-width: 767px) {
  .navbar {
    height: 10vh;
  }
  .mt11 {
    margin-top: 11px;
  }
  .mt6 {
    margin-top: 6px;
  }
  .menu {
    cursor: pointer;
    font-size: 1.9rem;
    margin-top: 12px;
  }
  .mobile-visible {
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  }

  .header .landing-page-header-bar {
    display: none;
  }

  .head-title {
    min-width: 100% !important;
    color: black !important;

    .head-title-span {
      background-color: #e6b31e;
      font-size: x-large !important;
    }
  }
  .ant-dropdown-menu-item {
    color: black !important;
  }

  .dropdown-menu {
    font-size: large;
    // top: 3vh;
    left: 3vw;
    background: white !important;
    color: white !important;
    // width: 30vw;
    text-align: left !important;
    li {
      font-size: 14px !important;
    }
  }

  .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover {
    color: black;
  }
}





